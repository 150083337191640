import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-server-maintenance',
  templateUrl: './server-maintenance.component.html',
  styleUrls: ['./server-maintenance.component.scss']
})
export class ServerMaintenanceComponent implements OnInit {

  constructor(private activatedRoute: ActivatedRoute) { }

  title = "Página em manutenção";
  subTitle = "Voltamos o mais cedo possível"
  message = "Lamentamos o incómodo, mas estamos a realizar a manutenção da página. Voltará a estar online em breve!";
  team = "— A equipa DSI"
  img = "./assets/politecnico_v-01.png";

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      console.log(params);

      if(params){
        this.title = params['title'] ? params['title'] : this.title;
        this.subTitle = params['subTitle'] ? params['subTitle'] : this.subTitle;
        this.message = params['message'] ? params['message'] : this.message;
        this.team = params['team'] ? params['team'] : this.team;
        this.img = params['img'] ? params['img'] : this.img;
      }

    });
  }

}
