import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ServerMaintenanceTwoComponent } from './pages/server-maintenance-two/server-maintenance-two.component';
import {ServerMaintenanceComponent} from "./pages/server-maintenance/server-maintenance.component";

const routes: Routes = [
  {path: '', component: ServerMaintenanceComponent},
  // {path: 'two', component: ServerMaintenanceTwoComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule {
}
