<div class="container">
  <div class="row h-100 justify-content-center align-items-center">
    <div class="row">
      <div class="col-sm-2">
<!--        <div class="row align-content-end">-->
          <img src="{{img}}" alt="logo IPL" style="max-width: 100%;">
<!--        </div>-->
      </div>

      <div class="col-sm-10">
        <div class="error-template">
          <h1>{{title}}</h1>
          <h3>{{subTitle}}</h3>
          <div>
            <p>{{message}}</p>
            <p>{{team}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
